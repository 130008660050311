var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.vendor_status == 1 ? _c('span', [_c('span', {
    staticClass: "badge badge-primary"
  }, [_vm._v("new")])]) : _vm.vendor_status == 2 ? _c('span', [_c('span', {
    staticClass: "badge badge-info"
  }, [_vm._v("waiting for review")])]) : _vm.vendor_status == 3 ? _c('span', [_c('span', {
    staticClass: "badge badge-secondary"
  }, [_vm._v("in review")])]) : _vm.vendor_status == 4 ? _c('span', [_c('span', {
    staticClass: "badge badge-danger"
  }, [_vm._v("rejected")])]) : _vm.vendor_status == 5 ? _c('span', [_c('span', {
    staticClass: "badge",
    staticStyle: {
      "background-color": "green"
    }
  }, [_vm._v("approved")])]) : _vm.vendor_status == 6 ? _c('span', [_c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v("clear")])]) : _c('span', [_c('span', {
    staticClass: "badge badge-danger"
  }, [_vm._v("banned")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }