<template>
  <span v-if="vendor_status == 1">
    <span class="badge badge-primary">new</span>
  </span>
  <span v-else-if="vendor_status == 2">
    <span class="badge badge-info">waiting for review</span>
  </span>
  <span v-else-if="vendor_status == 3">
    <span class="badge badge-secondary">in review</span>
  </span>
  <span v-else-if="vendor_status == 4">
    <span class="badge badge-danger">rejected</span>
  </span>
  <span v-else-if="vendor_status == 5">
    <span class="badge" style="background-color: green">approved</span>
  </span>
  <span v-else-if="vendor_status == 6">
    <span class="badge badge-warning">clear</span>
  </span>
  <span v-else>
    <span class="badge badge-danger">banned</span>
  </span>
</template>
<script>
export default {
  name: 'VendorStatus',
  props: {
    vendor_status: {
      type: Number,
      required: true,
    },
  },
};
</script>
